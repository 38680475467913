@font-face {
  font-family: 'Anton';
  src: url('./assets/fonts/Anton/anton-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/Anton/anton-regular-webfont.woff') format('woff'),
    url('./assets/fonts/Anton/Anton-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Khula-Regular';
  src: url('./assets/fonts/Khula/khula-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/Khula/khula-regular-webfont.woff') format('woff'),
    url('./assets/fonts/Khula/Khula-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Khula-Bold';
  src: url('./assets/fonts/Khula/khula-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/Khula/khula-bold-webfont.woff') format('woff'),
    url('./assets/fonts/Khula/Khula-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Khula-SemiBold';
  src: url('./assets/fonts/Khula/khula-semibold-webfont.woff2') format('woff2'),
    url('./assets/fonts/Khula/khula-semibold-webfont.woff') format('woff'),
    url('./assets/fonts/Khula/Khula-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Khula-ExtraBold';
  src: url('./assets/fonts/Khula/khula-extrabold-webfont.woff2') format('woff2'),
    url('./assets/fonts/Khula/khula-extrabold-webfont.woff') format('woff'),
    url('./assets/fonts/Khula/Khula-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('./assets/fonts/Lato/lato-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/Lato/lato-regular-webfont.woff') format('woff'),
    url('./assets/fonts/Lato/lato-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('./assets/fonts/Lato/lato-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/Lato/lato-bold-webfont.woff') format('woff'),
    url('./assets/fonts/Lato/lato-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish-Regular';
  src: url('./assets/fonts/Mulish/Mulish-Regular.woff2') format('woff2'),
    url('./assets/fonts/Mulish/Mulish-Regular.woff') format('woff'),
    url('./assets/fonts/Mulish/Mulish-Regular.ttf') format('truetype');
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  line-height: 1.5;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-family: 'Anton', sans-serif;
  font-size: 54px;
  line-height: 67px;
  letter-spacing: 1px;
}
h2 {
  font-family: 'Anton', sans-serif;
  font-size: 37px;
  line-height: 47px;
  letter-spacing: 1px;
}
h3 {
  font-family: 'Anton', sans-serif;
  font-size: 25px;
  line-height: 33px;
  letter-spacing: 1px;
}
h4 {
  font-family: 'Anton', sans-serif;
  font-size: 17.3px;
  line-height: 24px;
  letter-spacing: 0.9px;
}
h5 {
  font-family: 'Anton', sans-serif;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 1px;
}
h6 {
  font-family: 'Anton', sans-serif;
  font-size: 10px;
  line-height: 13.4px;
  letter-spacing: 1px;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: 'Khula-Regular', sans-serif;
}
